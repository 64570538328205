<template>
  <div class="main">
    <div class="topbar">
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="violateType"
                    placeholder="违规类型"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in violateTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Select v-model="workType"
                    placeholder="工种"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in workTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="name"
                          placeholder="姓名"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="personTreeArr"
                          search
                          checked
                          @onCheckChange="onChangeName">
            </MyTreeSelect>
            <DatePicker type="daterange"
                        :value="date"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        style="width:260px"></DatePicker>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    :ghost="buttonIndex == 0 ? false : true"
                    @click.stop="onClickLastWeek">上周</Button>
            <Button type="primary"
                    class="m-l-10"
                    :ghost="buttonIndex == 1 ? false : true"
                    @click.stop="onClickCurrentWeek">本周</Button>
            <Button type="primary"
                    class="m-l-10"
                    :ghost="buttonIndex == 2 ? false : true"
                    @click.stop="onClickLastMonth">上月</Button>
            <Button type="primary"
                    class="m-l-10"
                    :ghost="buttonIndex == 3 ? false : true"
                    @click.stop="onClickCurrentMonth">本月</Button>
            <Button type="primary"
                    class="m-l-10"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <Echarts ref="echarts"
               height="368px"
               :options="options"
               :data="violateChartData"
               v-show="violateChartData.length"></Echarts>
      <div class="noData"
           v-show="violateChartData.length===0">
        <img src="../../../../assets/common/tubiaono.png">
        暂无数据
      </div>
    </div>
    <div class="content">
      <Tabs v-model="currentViolateTabIndex"
            class="tabs">
        <TabPane v-for="item in violateTabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               @on-selection-change="onChangeSelect">
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"></PositionDetailModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getCurrentMonth, getLastMonth, getCurrentWeek, getLastWeek, formatSecond } from '@/utils/dateRangUtil'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import Echarts from '@/components/common/Echarts';
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
export default {
  components: {
    MyTreeSelect,
    Echarts,
    PositionDetailModal
  },
  data () {
    return {
      buttonIndex: null,
      violateType: '',
      violateTypeArr: [],
      workType: '',
      nameId: '',
      name: '',
      date: [],
      currentViolateTabIndex: 0,
      violateTabArr: [
        {
          name: '违规汇总'
        },
        {
          name: '违规明细'
        },
      ],
      options: {
        legend: {},
        tooltip: {},
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        dataset: {
          source: []
        },
        series: [
          {
            type: 'bar'
          }
        ]
      },
      violateChartData: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsViolateSummary: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'personName',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickViolatePersonName(row)
                }
              }
            }, row.personName);
          }
        },
        {
          title: '工种',
          key: 'personTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计违规次数',
          key: 'hjwgcs',
          tooltip: true,
          align: 'center'
        },
        {
          title: '离岗',
          key: 'lg',
          tooltip: true,
          align: 'center'
        },
        {
          title: '停留',
          key: 'tl',
          tooltip: true,
          align: 'center'
        },
        {
          title: '聚众',
          key: 'jz',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsViolateDetail: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'personName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规类型',
          key: 'violationTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规时间',
          key: 'date',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规地点',
          key: 'address',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickViolateAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '违规时长',
          key: 'duration',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = formatSecond(row.duration)
            return h('span', text);
          }
        },
      ],
      data: [],
      selectArr: [],
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0
    };
  },
  watch: {
    currentViolateTabIndex () {
      this.onClickSearch()
    },
  },
  computed: {
    ...mapGetters({
      'personTreeArr': 'getPersonTreeList',
    }),
    workTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getWorkTypeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentViolateTabIndex) {
        case 0:
          columns = this.columnsViolateSummary
          break;
        case 1:
          columns = this.columnsViolateDetail
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    ...mapActions([
      'updatePersonTreeList',
      'updateWorkTypeAllList',
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updatePersonTreeList()
      this.updateWorkTypeAllList()
      this.$http.getWorkRuleItemList({ type: 2 }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.violateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.customName
            item.name = item.customName
            return item
          }))
        }
      })
      this.onClickCurrentMonth()
    },
    // 选择人员
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'user') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.nameId = idArr.join()
      this.name = valueArr.join()
    },
    onChangeDate (date) {
      this.date = date
      this.buttonIndex = null
    },
    // 本周
    onClickCurrentWeek () {
      this.date = getCurrentWeek()
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 上周
    onClickLastWeek () {
      this.date = getLastWeek()
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 查询
    onClickSearch () {
      this.getEcharts()
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置搜索条件
    resetSearchData () {
      this.violateType = ''
      this.workType = ''
      this.nameId = ''
      this.name = ''
      this.date = []
      this.currentViolateTabIndex = 0
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getEcharts () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        violationTypeName: this.violateType === 0 ? '' : this.violateType,
        personTypeId: this.workType === 0 ? '' : this.workType,
        personId: this.nameId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.$http.getRoadPlanViolateEcharts(params).then(res => {
        if (res.code === 200) {
          if (res.result.length) {
            let arr = [
              ['人员名称', '人员违规']
            ]
            this.violateChartData = arr.concat(res.result.map(item => {
              return [item.personName, item.count]
            }))
          } else {
            this.violateChartData = []
          }
        }
      })
    },
    getList () {
      switch (this.currentViolateTabIndex) {
        case 0:
          this.getViolateSummaryList()
          break;
        case 1:
          this.getViolateDetailList()
          break;
        default:
          break;
      }
    },
    // 违规汇总列表
    getViolateSummaryList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        violationTypeName: this.violateType === 0 ? '' : this.violateType,
        personTypeId: this.workType === 0 ? '' : this.workType,
        personId: this.nameId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.tableLoading = true
      this.$http.getRoadPlanViolateSummaryList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 违规明细列表
    getViolateDetailList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        violationTypeName: this.violateType === 0 ? '' : this.violateType,
        personTypeId: this.workType === 0 ? '' : this.workType,
        personId: this.nameId,
        startDate: this.date[0],
        endDate: this.date[1],
      }
      this.tableLoading = true
      this.$http.getPersonViolationList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击违规汇总人员姓名
    onClickViolatePersonName (row) {
      this.nameId = row.personId
      this.name = row.personName
      this.currentViolateTabIndex = 1
    },
    // 点击违规明细地址
    onClickViolateAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow-y: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .table {
      margin-top: 16px;
      flex: 1;
    }
    .noData {
      height: 368px;
      img {
        width: 172px;
        height: 100px;
      }
    }
  }
}
</style>